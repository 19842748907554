import { title } from '@core/utils/filter';

export default [
  {
    key: 'selected',
    label: '',
    sortable: false,
    visible: true,
  },
  {
    key: 'nickname',
    label: 'Nickname',
    sortable: true,
    formatter: title,
    visible: true,

  },
  {
    key: 'parent',
    label: 'Source / SubSource',
    sortable: false,
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'mobile',
    label: 'Mobile',
    sortable: true,
    visible: true,
  },
  {
    key: 'fanpage',
    label: 'Fanpage',
    sortable: false,
    visible: true,
  },
  {
    key: 'attend',
    label: 'Task',
    sortable: false,
    visible: true,
  },
  {
    key: 'created_at',
    label: 'Created date',
    sortable: true,
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'owner',
    label: 'Owner',
    sortable: true,
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'tracking',
    label: 'Tracking',
    tdClass: 'text-center pt-1',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'client_ceo',
    label: 'client of ceo',
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },
  {
    key: 'potential',
    label: 'Potential',
    sortable: false,
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: 'actions',
    label: 'Actions',
    tdClass: 'text-center',
    thClass: 'text-center',
    visible: true,
  },

];
